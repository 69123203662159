<template>
  <div
    class="setting-icon align-center justify-center"
    style="overflow: hidden"
  >
    <v-dialog
      @click:outside="clickOutside"
      v-model="dialog"
      id="campaign-config-modal-fbee673e"
      overlay-opacity="0.8"
      max-width="657px"
      style="overflow: hidden"
    >
      <template v-slot:activator="{}">
        <Images
          id="config-icon-2cd986a8"
          name="setting.svg"
          class="pointer"
          @click.native="open"
        />
      </template>
      <v-card
        id="campaign-config-card-3caabdd6"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          overflow: hidden;
        "
      >
        <v-container>
          <form action="#" ref="form" id="AddForm" @submit.prevent="onProject">
            <v-row align="center" style="border-bottom: 1px solid #f4f4f4">
              <v-col cols="1"></v-col>
              <v-col
                cols="auto"
                style="padding-top: 0; padding-bottom: 6px; padding-left: 0"
              >
                <Texting type="p2bold grey2">Campaign Configuration</Texting>
              </v-col>
              <v-divider style="border: hidden" />
              <v-col cols="auto" style="padding-top: 8px; padding-right: 20px">
                <Images
                  id="config-close-icon-942f3b12"
                  name="close.svg"
                  class="pointer"
                  v-on:click.native="close"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10" style="padding-left: 0; padding-bottom: 0">
                <v-row>
                  <v-col cols="12" style="padding-bottom: 0">
                    <v-text-field
                      id="config-projectTitle-input-17b67d00"
                      label="Campaign Title*"
                      placeholder="Campaign Title"
                      @blur="$v.form.projectTitle.$touch()"
                      name="projectTitle"
                      v-model="form.projectTitle"
                      :disabled="isArchive"
                      :class="{
                        'error--text v-input--has-state':
                          ($v.form.projectTitle.$dirty &&
                            !$v.form.projectTitle.required) ||
                          ($v.form.projectTitle.$error &&
                            !$v.form.projectTitle.projectTitleValid.valid &&
                            $v.form.projectTitle.required),
                      }"
                      onfocus="this.placeholder = ''"
                      hide-details
                      outlined
                    ></v-text-field>
                    <div class="redColor" style="padding-top: 5px">
                      <Texting
                        v-if="
                          $v.form.projectTitle.$dirty &&
                          !$v.form.projectTitle.required
                        "
                        type="p1"
                        >Title is required</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="
                          $v.form.projectTitle.$error &&
                          !$v.form.projectTitle.projectTitleValid.valid &&
                          $v.form.projectTitle.required &&
                          this.form.projectTitle.length < 60
                        "
                        >Title should contain only letters and numbers
                        (a-z/A-Z/0-9)</Texting
                      >
                      <Texting
                        type="p1"
                        v-else-if="this.form.projectTitle.length >= 60"
                        >Title should be less than 60 characters</Texting
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="10"
                style="padding-left: 0; padding-top: 0; padding-bottom: 0"
              >
                <v-row align="center" style="padding-bottom: 10px">
                  <v-col cols="2" style="max-width: 57px">
                    <MySwitch
                      id="config-notify-email-switch-89563b00"
                      :outline="true"
                      @change="notifyEmail"
                      :disabled="isArchive"
                      :checked="isArchive ? false : this.switchEmail"
                    />
                  </v-col>
                  <v-col cols="8" style="padding-left: 28px">
                    <Texting type="p1 grey2"
                      >Notify me when store updates occur</Texting
                    >
                  </v-col>
                  <div
                    v-if="$v.form.topUpNotificationType.$error && switchEmail"
                    class="redColor"
                  >
                    <Texting>Please choose one</Texting>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" style="border-bottom: 1px solid #f4f4f4">
              <v-col
                cols="10"
                style="padding-left: 0; padding-top: 0; padding-bottom: 28px"
              >
                <v-row align="center" style="min-height: 56px">
                  <v-col cols="2" sm="2" style="max-width: 57px">
                    <MySwitch
                      id="config-notify-sms-switch-a5b8ec34"
                      :outline="true"
                      @change="notifySms"
                      :disabled="isArchive"
                      :checked="isArchive ? false : this.switchSms"
                    />
                  </v-col>
                  <v-col cols="auto" class="pr-0" style="padding-left: 28px">
                    <Texting type="p1 grey2"
                      >Receive critical alerts via SMS</Texting
                    >
                  </v-col>
                  <div
                    v-if="$v.form.topUpNotificationType.$error && switchSms"
                    class="redColor"
                  >
                    <Texting>Please choose one</Texting>
                  </div>
                  <v-divider style="border-style: none"></v-divider>
                  <v-col
                    v-if="this.switchSms && !user.phoneNumber"
                    class="pb-2 pb-sm-0 pl-0"
                    cols="auto"
                    sm="6"
                    style="padding: 0 12px 0 12px"
                  >
                    <template>
                      <div style="max-height: 60px">
                        <vue-tel-input
                          style="height: 56px"
                          id="config-telephone-id"
                          @focus="phoneInputFocus = true"
                          @blur="phoneInputFocus = false"
                          @close="onClose"
                          @country-changed="onCountryChanged($event)"
                          :style="{
                            border: phoneInputFocus
                              ? '2px solid #1976d2'
                              : '1px solid #A7A0B8',
                          }"
                          mode="international"
                          @input="onInput"
                          :autoDefaultCountry="false"
                          :validCharactersOnly="true"
                          :enabledCountryCode="true"
                          enabledFlags
                          :inputOptions="{
                            showDialCode: false,
                            tabindex: 1,
                            id: 'campaign-config-phone-input',
                          }"
                          v-model="form.phoneNumber"
                          outlined
                        ></vue-tel-input>
                        <div v-show="phoneValidError" class="mylabel">
                          <Texting type="p1 Fivethly"
                            >Phone number isn't valid</Texting
                          >
                        </div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="10"
                style="padding-top: 30px; padding-bottom: 18px; padding-left: 0"
              >
                <div class="d-flex align-center justify-space-between">
                  <div
                    v-if="!isArchive && !project.isActiveProject"
                    class="d-flex mr-4 archive-icon"
                  >
                    <v-tooltip
                      content-class="my-tooltip"
                      color="rgba(38,39,40,0.9)"
                      max-width="200px"
                      top
                    >
                      <template v-slot:activator="{ on }">
                        <span style="height: 33px" v-on="on">
                          <div
                            style="height: 33px; width: 33px"
                            class="justify-center d-flex align-center"
                          >
                            <Images
                              id="config-sendtoarchive-icon-d7628862"
                              v-on:click.native="
                                sendToArchive(project.projectId)
                              "
                              class="pointer"
                              name="archive__icon.svg"
                            />
                          </div>
                        </span>
                      </template>
                      <Texting type="p1n mywhite">Send to Archive</Texting>
                      <div class="arrow-tooltip" style="left: 39px"></div>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="
                      !isArchive &&
                      project.isActiveProject &&
                      project.campaignStatus === 'INDEMAND' &&
                      project.subId !== null &&
                      project.packageType !== 'ONLY_FREE_TRIAL'
                    "
                    class="d-flex mr-4 menu-icon"
                  >
                    <v-menu
                      id="config-actions-menu-fd10c678"
                      v-model="actions"
                      :return-value="action"
                      max-width="158px"
                      nudge-bottom="40"
                      nudge-right="8"
                      offset-x
                      right
                      top
                    >
                      <template v-slot:activator="{}">
                        <Images
                          id="actions-menu-icon-13f4a83c"
                          @click.native="actions = true"
                          name="menu_more_icon.png"
                          class="pointer"
                        />
                      </template>
                      <v-list
                        style="
                          background: #ffffff;
                          border: 1px solid #f4f4f4;
                          box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.16);
                          border-radius: 10px;
                        "
                      >
                        <v-list-item
                          v-for="(item, index) in items"
                          :id="'actions-menu-list-item-' + index"
                          :key="index"
                          @click="action = item.title"
                        >
                          <v-list-item-title class="pointer">
                            <Texting type="p1 MainDark">{{
                              item.title
                            }}</Texting>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <v-divider style="border-style: none" />
                  <div class="d-flex">
                    <div class="d-flex">
                      <Button
                        id="config-cancel-button-44574610"
                        texting="p2bold grey2"
                        size="normal"
                        :handleClick="close"
                        type="cancel"
                        >cancel</Button
                      >
                    </div>
                    <div class="d-flex ml-4">
                      <Button
                        id="config-save-button-50e54896"
                        b_type="submit"
                        texting="p2bold mywhite"
                        size="normal"
                        :handleClick="function () {}"
                        type="primary"
                        >save</Button
                      >
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card>
    </v-dialog>
    <ConfirmCancelation
      @closed="action = ''"
      @close-config="onConfirmClosed"
      :project="project"
      :active="action === 'Cancel Subscription'"
    />
  </div>
</template>

<script>
import myServices from "@/services/resource";
import Texting from "./Texting";
import Images from "./MyImages";
import MySwitch from "./MySwitch";
import Button from "./Button";
import router from "@/plugins/router";
import ConfirmCancelation from "./ConfirmCancelation";
import { required } from "vuelidate/lib/validators";
import { projectTitleValid } from "../utils/projectTitleValid";
import { mapState } from "vuex";
import axios from "axios";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
  components: {
    VueTelInput,
    ConfirmCancelation,
    Button,
    MySwitch,
    Images,
    Texting,
  },
  props: {
    project: Object,
    isArchive: Boolean,
  },
  data: () => ({
    phoneInputFocus: false,
    dialog: false,
    switchEmail: false,
    switchSms: false,
    actions: false,
    confirmancelation: false,
    action: "",
    phoneValidError: false,
    phoneValid: false,
    dialCode: null,
    items: [
      {
        title: "Cancel Subscription",
      },
    ],
    form: {
      projectTitle: "",
      topUpNotificationType: "NDNE",
      resiveCriticalAlerts: false,
      phoneNumber: "",
    },
  }),
  validations: {
    form: {
      projectTitle: {
        required,
        projectTitleValid,
      },
      topUpNotificationType: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      projects: "projects",
      user: "user",
    }),
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.form.projectTitle = this.project.projectTitle;
        this.form.topUpNotificationType = this.project.topUpNotificationType;
        this.switchEmail =
          this.form.topUpNotificationType === "EVERY_TOP_UP" ||
          this.form.topUpNotificationType === "DAILY_TOP_UP_SUMMARY"
            ? true
            : false;
        this.switchSms = this.project.resiveCriticalAlerts;
        this.form.resiveCriticalAlerts = this.project.resiveCriticalAlerts;
      } else {
        //this.$v.form.reset();
        if (this.$route.query.projectconfig === "true") {
          router.push({
            name: "user",
          });
        }
      }
    },
    action: function (val) {
      if (val === "Cancel Subscription") {
        myServices.myAnalyticsEvents(
          this,
          "Subscription cancelation",
          "Cancel subscription button click",
        );
        /*this.$ga.event('Subscription cancelation', 'Cancel subscription button click');
                this.$mixpanel.track('Subscription cancelation', {
                  action: 'Cancel subscription button click',
                });*/
      }
    },
  },
  created() {
    if (this.$route.query.projectconfig === "true") {
      const projectId = this.$route.query.projectid;
      if (this.project.projectId === projectId) {
        this.dialog = true;
      }
    }
  },
  async mounted() {
    this.form = {
      ...this.project,
    };
    if (this.user.phoneNumber) this.form.phoneNumber = this.user.phoneNumber;
  },
  methods: {
    onConfirmClosed() {
      this.dialog = false;
      this.$emit("project-deleted");
    },
    phoneChanged() {
      this.phonechanged = true;
    },
    onCountryChanged(obj) {
      if (obj.dialCode) {
        this.form.phoneNumber = "+" + obj.dialCode;
        document.getElementById("campaign-config-phone-input").focus();
      }
    },
    onClose() {
      if (this.dialCode && this.form.phoneNumber === "") {
        this.form.phoneNumber = "+" + this.dialCode;
        document.getElementById("campaign-config-phone-input").focus();
      }
    },
    onInput(str, obj) {
      this.phoneValid = obj.valid;
      this.dialCode = obj.country.dialCode;
      if (
        obj.valid ||
        (this.form.phoneNumber === "" &&
          !this.form.phoneNumber.includes("-") &&
          !this.form.phoneNumber.substring(1).includes("+") &&
          this.form.phoneNumber.charAt(0) === "+") ||
        this.form.phoneNumber === "" ||
        this.form.phoneNumber.substring(1) === obj.country.dialCode
      ) {
        this.phoneValidError = false;
        this.phoneValid = true;
      } else {
        this.phoneValidError = true;
        this.phoneValid = false;
      }
      /*obj.isValid ? document.getElementById("campaign-config-phone-input").type = 'text' : document.getElementById("campaign-config-phone-input").type = 'number';
            if(document.getElementById("campaign-config-phone-input").value === '' && !obj.isValid) {
              this.form.phoneNumber = '';
            }*/
    },
    open: function () {
      this.dialog = true;
      if (!this.user.phoneNumber && this.form.resiveCriticalAlerts) {
        //this.form.phoneNumber = "";
      } else this.form.phoneNumber = this.user.phoneNumber;
    },
    close: function () {
      this.phoneValidError = false;
      this.phoneValid = true;
      this.dialog = false;
    },
    sendToArchive(id) {
      axios
        .put(`/api/project/inArchive/${id}`)
        .then(() => {
          this.$store.dispatch("getProjects");
        })
        .catch((error) => {
          this.open();
          myServices.showErrorMessage(this, error);
        });
    },
    clickOutside() {
      if (
        this.form.projectTitle !== this.project.projectTitle ||
        this.form.topUpNotificationType !==
          this.project.topUpNotificationType ||
        this.form.resiveCriticalAlerts !== this.project.resiveCriticalAlerts
      ) {
        this.formEdited = true;
      } else {
        this.dialog = false;
      }
    },
    onProject() {
      if (
        this.switchSms &&
        !this.user.phoneNumber &&
        (!this.form.phoneNumber ||
          !this.phoneValid ||
          this.form.phoneNumber.substring(1) === this.dialCode)
      ) {
        this.phoneValidError = true;
        return;
      }
      if (this.$v.$invalid) {
        this.$v.form.$touch();
        this.dialog = true;
        return;
      }
      if (!this.form.resiveCriticalAlerts)
        this.form.phoneNumber = this.user.phoneNumber;

      let updateForm = {
        projectTitle: this.form.projectTitle,
        topUpNotificationType: this.form.topUpNotificationType,
        resiveCriticalAlerts: this.form.resiveCriticalAlerts,
        phoneNumber: this.form.phoneNumber,
      };

      let pId =
        this.$route.query.projectconfig === "true"
          ? this.$route.query.projectid
          : this.$props.project.projectId;

      axios
        .put(`/api/project/updateProject/${pId}`, updateForm)
        .then(() => {
          this.close();
          this.$store.dispatch("getProjects");
          this.$store.dispatch("getAuthUser");
        })
        .catch((error) => {
          this.open();
          myServices.showErrorMessage(this, error);
        });
    },
    notifyEmail: function (val) {
      if (!this.isArchive) {
        this.switchEmail = val;
        this.form.topUpNotificationType = val ? "EVERY_TOP_UP" : "NONE";
      }
    },
    notifySms: function (val) {
      if (!val) {
        this.form.phoneNumber = "";
        this.phoneValidError = false;
      } else {
        if (this.form.phoneNumber === this.dialCode && this.dialCode) {
          this.form.phoneNumber = "+" + this.dialCode;
          document.getElementById("campaign-config-phone-input").focus();
        }
      }
      if (!this.isArchive) {
        this.switchSms = val;
        this.form.resiveCriticalAlerts = val;
      }
    },
  },
};
</script>

<style scoped>
#config-telephone-id {
  border: 1px solid #a7a0b8;
  border-radius: 5px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
