<template>
  <div>
    <v-dialog
      id="confirm-cancelation-modal-5d45c424"
      v-model="dialog"
      overlay-opacity="0.8"
      max-width="657px"
      style="overflow: hidden"
    >
      <v-card
        id="confirm-cancelation-card-6bd54442"
        style="
          background: #ffffff;
          box-shadow: 0 18px 23px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        "
      >
        <v-container style="padding-bottom: 18px">
          <v-row align="center">
            <v-divider style="border: hidden" />
            <v-col
              cols="auto"
              style="padding-top: 8px; padding-right: 20px; padding-bottom: 0"
            >
              <Images
                id="confirm-cancelation-close-icon-913b1e96"
                name="close.svg"
                class="pointer"
                v-on:click.native="close"
              />
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="auto" style="padding-top: 0">
              <Images name="robot_cancellation.png" />
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto" style="padding-top: 1px; padding-bottom: 2px">
              <Heading type="h3 dark-asphalt">Confirm cancellation</Heading>
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto">
              <Texting type="p3 grey2">Service will be terminated at:</Texting>
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto" style="padding-top: 0; padding-bottom: 48px">
              <Texting type="p3b grey2">
                {{ getCancellationDate() }}
              </Texting>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <Button
                id="confirm-cancelation-not-now-button-b04c499a"
                texting="p2bold grey2"
                size="normal"
                :handleClick="close"
                type="cancel"
                >not now</Button
              >
            </v-col>
            <v-col cols="auto">
              <Button
                id="confirm-cancelation-confirm-button-ba0220e0"
                b_type="submit"
                size="normal"
                :handleClick="onCancelSubscription"
                type="red"
                >confirm</Button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!--<AlertModal :active="canceled" text="Your subscription has been canceled successfully." />-->
    <Confirmation
      ref="confirm"
      text1="Your subscription has been canceled successfully."
    />
  </div>
</template>

<script>
import Images from "./MyImages";
import Heading from "./Heading";
import Texting from "./Texting";
import Button from "./Button";
//import AlertModal from "../AlertModal/AlertModal";
import axios from "axios";
import Confirmation from "@/components/Confirmation";
import { mapState } from "vuex";
import myServices from "@/services/resource";

export default {
  components: { Confirmation, Button, Texting, Heading, Images },
  props: {
    active: Boolean,
    project: Object,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState({
      user: "user",
    }),
  },
  watch: {
    active: function (val) {
      if (val) {
        this.dialog = true;
      }
    },
    dialog: function (val) {
      if (!val) {
        this.$emit("closed");
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    getCancellationDate() {
      let endDate = new Date(this.project.packageEndDate);
      if (
        this.project.campaignStatus === "INDEMAND" &&
        new Date(this.user.freeTrialEndDate).getTime() > new Date().getTime()
      ) {
        endDate = new Date(this.user.freeTrialEndDate);
      }
      let hours = endDate.getHours();
      var ampm = hours >= 12 ? "PM" : "AM";
      let day = endDate.toString().substr(0, 3);
      let month = endDate.toString().substr(4, 3);
      let dayint = endDate.toString().substr(8, 2).trim();
      let year = endDate.toString().substr(11, 4);
      let hour = endDate.toString().substr(16, 5);
      return (
        hour +
        " " +
        ampm +
        ", " +
        day +
        ", " +
        month +
        " " +
        dayint +
        ", " +
        year
      );
    },
    onCancelSubscription() {
      myServices.myAnalyticsEvents(
        this,
        "Subscription cancelation",
        "Confirm cancellation button click",
      );
      /*this.$ga.event('Subscription cancelation', 'Confirm cancellation button click');
      this.$mixpanel.track('Subscription cancelation', {
        action: 'Confirm cancellation button click',
      });*/
      //console.log("projectId: ", this.$props.project.projectId);
      axios
        .patch(
          `/api/project/cancelSubscription/${this.$props.project.projectId}`,
        )
        .then(() => {
          this.$store.dispatch("getProjects");
          this.$refs.confirm.show();
          this.$emit("close-config");
          this.close();
        })
        .catch((error) => {
          this.open();
          myServices.showErrorMessage(this, error);
          /*let message = "Oops, something went wrong";
					if (error.response && error.response.data) {
						message = error.response.data.message;
						this.$toasted.error(message, {
							duration: 5000,
							className: "toast-error",
						});
					}*/
        });
    },
  },
};
</script>
