<template>
  <label v-if="outline" class="switch">
    <input
      :id="id"
      type="checkbox"
      :disabled="disabled"
      v-bind:checked="checked"
      v-on:change="$emit('change', $event.target.checked)"
    />
    <span v-if="checked" class="slider round" style="color: #ffffff"
      ><span
        class="text"
        style="position: relative; margin-left: 11px; bottom: 1px"
        >Yes</span
      ></span
    >
    <span v-else class="slider round" style="color: #595959"
      ><span
        class="text"
        style="margin-left: 27px; position: relative; bottom: 1px"
        >No</span
      ></span
    >
  </label>
  <label v-else class="switch-standart">
    <input
      :id="id"
      value="false"
      type="checkbox"
      :disabled="disabled"
      v-bind:checked="checked"
      v-on:change="$emit('change', $event.target.checked)"
    />
    <span
      v-if="checked"
      class="slider-standart round-standart"
      style="color: #ffffff"
    ></span>
    <span
      v-else
      class="slider-standart round-standart"
      style="color: #595959"
    ></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: Boolean,
    disabled: Boolean,
    outline: Boolean,
    id: String,
  },
};
</script>
